
// Vue
import {Options, Vue} from "vue-class-component";

// Models
import {AppAdministratorTrainingsRootListFormRemove} from "@/models/app/administrator/trainings/root/list/form/remove";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElDialog,
  ElForm,
  ElFormItem,
  ElButton,
} from "element-plus";
import {Delete} from "@element-plus/icons-vue";

// Services
import {postRequest} from "@/services/api/request";

@Options({
  props: [
    "training",
  ],
  components: {
    ElDialog,
    ElForm,
    ElFormItem,
    ElButton,
    Delete,
  },
})
export default class AppAdministratorTrainingsRootListFormRemoveIndexVue extends Vue {
  isLoading: boolean | null = false;
  isDialogVisible: boolean | null = false;

  formData: AppAdministratorTrainingsRootListFormRemove = new AppAdministratorTrainingsRootListFormRemove();

  training: AppAdministratorTrainingsRootListFormRemove = new AppAdministratorTrainingsRootListFormRemove();

  $refs!: {
    formComponent: HTMLFormElement;
  };

  validateForm(): void {
    this.$refs.formComponent.validate((response: any) => {
      if (response) {
        this.submitForm();
      }
    });
  }

  async submitForm(): Promise<void> {
    this.isLoading = true;
    await postRequest({
      uri: "/common/training/remove",
      formData: this.formData,
      isProtected: true,
      isSuccessNotificationVisible: true,
      isErrorNotificationVisible: true,
    }).then((r: ResponseIndex) => {
      if (r.status === "success") {
        this.$emit('getTrainings');
        this.isDialogVisible = false;
        this.resetFormData();
      }
    });
    this.isLoading = false;
  }

  setFormData(): void {
    this.formData = this.training;
  }

  resetFormData(): void {
    this.formData = new AppAdministratorTrainingsRootListFormRemove();
  }

  updated(): void {
    this.setFormData();
  }
}
