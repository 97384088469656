
// Vue
import {Options, Vue} from "vue-class-component";

// Models
import {AppAdministratorTrainingsRootListFormUpdate} from "@/models/app/administrator/trainings/root/list/form/update";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElDialog,
  ElForm,
  ElFormItem,
  ElInput,
  ElInputNumber,
  ElSelect,
  ElOption,
  ElDatePicker,
  ElTimeSelect,
  ElButton,
} from "element-plus";
import {Edit} from "@element-plus/icons-vue";

// Services
import {postRequest} from "@/services/api/request";

@Options({
  props: [
    "training",
  ],
  components: {
    ElDialog,
    ElForm,
    ElFormItem,
    ElInput,
    ElInputNumber,
    ElSelect,
    ElOption,
    ElDatePicker,
    ElTimeSelect,
    ElButton,
    Edit,
  },
})
export default class AppAdministratorTrainingsRootListFormUpdateIndexVue extends Vue {
  isLoading: boolean | null = false;
  isDialogVisible: boolean | null = false;

  training: AppAdministratorTrainingsRootListFormUpdate = new AppAdministratorTrainingsRootListFormUpdate();

  formData: AppAdministratorTrainingsRootListFormUpdate = new AppAdministratorTrainingsRootListFormUpdate();
  formDataRules = {
    content: {
      date: [
        {
          required: true,
          message: "Polje nije popunjeno",
          trigger: "blur",
        },
      ],
    },
  };

  $refs!: {
    formComponent: HTMLFormElement;
  };

  validateForm(): void {
    this.$refs.formComponent.validate((response: any) => {
      if (response) {
        this.submitForm();
      }
    });
  }

  async submitForm(): Promise<void> {
    this.isLoading = true;
    await postRequest({
      uri: "/common/training/update",
      formData: this.formData,
      isProtected: true,
      isSuccessNotificationVisible: true,
      isErrorNotificationVisible: true,
    }).then((r: ResponseIndex) => {
      if (r.status === "success") {
        this.$emit('getTrainings');
        this.isDialogVisible = false;
        this.resetFormData();
      }
    });
    this.isLoading = false;
  }

  setFormData(): void {
    const fields = [];

    this.training.training_category.content.fields.forEach((fieldTrainingCategory: any) => {
      fields.push({
        value: null,
        name: fieldTrainingCategory.name,
        property: fieldTrainingCategory.property,
      });
    });

    fields.forEach((field: any) => {
      const fieldTraining = this.training.content.fields.filter((item: any) => {
        return item.property === field.property;
      });

      if (fieldTraining.length === 0) {
        this.training.content.fields.push({
          value: null,
          name: field.name,
          property: field.property,
        });
      }
    });

    this.formData = this.training;
  }

  resetFormData(): void {
    this.formData = new AppAdministratorTrainingsRootListFormUpdate();
  }

  updated(): void {
    this.setFormData();
  }
}
